import { useEffect, useState, useContext } from "react";
import "./index.scss";
import BuyBtn from "../../components/buyBtn";
import { getProdDetail } from "../../apis/prod";
import { useParams } from "react-router-dom";
import { LanContext } from "../../utils/lanContext";

export default function Index() {
  const { curPageLan, pageLan } = useContext(LanContext);
  let { id } = useParams();
  const [val, setVal] = useState("");
  const [prodDetail, setProdDetail] = useState({});
  const [data, setData] = useState({});
  useEffect(() => {
    setVal(id);
  }, [id]);
  useEffect(() => {
    if (val) {
      getProdDetail(val).then((res) => {
        setProdDetail(res.data);
      });
    }
  }, [val]);
  // 根据语言渲染数据
  useEffect(() => {
    if (curPageLan) {
      setData(prodDetail["prod" + curPageLan]);
    }
  }, [prodDetail, curPageLan]);
  return (
    <div className="page-product">
      <div className="buy-wrap bg-gray">
        <div className="buy flex justify-between text-white align-center">
          <div className="buy-prod-names product-buy-prod-name-text">{data?.prodName}</div>
          <BuyBtn buyUrl={data?.buyUrl} text={pageLan.product?.buyText} />
        </div>
      </div>
      <div className="content">
        <div className="prod-name">
          <img src={process.env.REACT_APP_BASE_API + data?.prodImg} className="prod-logo" />
          {/* <div className="name text-center product-prod-name-text">{data?.prodName}</div> */}
        </div>
        {data?.sysProdFeatureList?.map((item, index) => {
          return (
            <div className="prod-item text-center text-bold" key={index}>
              <div className="title product-feature-title-text">{item.featureTitle}</div>
              <div className="des product-feature-des-text">{item.featureDes}</div>
              <img
                src={process.env.REACT_APP_BASE_API + item.featureImg}
                className="img"
              />
            </div>
          );
        })}
        <div className="prod-specifications text-left">
          <div className="title product-spec-title-text">{pageLan.product?.technical}</div>
          {data?.sysProdSpecificationList?.map((item, index) => {
            return (
              <div
                className="spec-item flex align-center flex-wrap"
                key={index}
              >
                <div className="item-left product-spec-item-title-text basis-df">
                  {item.specificationName}
                </div>
                <div className="item-right basis-xl product-spec-item-des-text">
                  {item.specificationDes}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
