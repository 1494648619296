import { useContext, useRef } from "react";
import "./index.scss";
import { Carousel } from "antd";
import { LanContext } from "../../utils/lanContext";
import left from "../../static/imgs/home/left.png";
import right from "../../static/imgs/home/right.png";

export default function Index() {
  const { pageLan } = useContext(LanContext);
  const ref = useRef(null);
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  return (
    <div className="page-home">
      <Carousel ref={ref} afterChange={onChange} autoplay dots={false}>
        {pageLan.swiperList?.map((item, index) => {
          return (
            <img
              key={index}
              className="banner-img pointer"
              src={process.env.REACT_APP_BASE_API + item.img}
              onClick={(e) => {
                e.stopPropagation();
                window.open(item.link);
              }}
            />
          );
        })}
      </Carousel>
      <img
        onClick={() => {
          ref.current?.prev();
        }}
        className="img-left pointer"
        src={left}
      />
      <img
        onClick={() => {
          ref.current?.next();
        }}
        className="img-right pointer"
        src={right}
      />
    </div>
  );
}
