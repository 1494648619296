import { Navigate } from "react-router-dom";
import Home from "../pages/home/index";
import Product from "../pages/product/index";
import Download from "../pages/download/index";
// import Periphery from "../pages/periphery/index";
import Search from "../pages/search/index";
import Common from "../pages/common/index";
import React from "react";

// 组件懒加载 在webpack中import会自动分包
// const About = React.lazy(() => import("../pages/About"))
// const Login = React.lazy(() => import("../pages/Login"))

const routes = [
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "/home",
    element: <Home />,
    // children: [
    //     {
    //         path: "/home",
    //         element: <Navigate to="/home/recommend" />
    //     },

    // ]
  },
  {
    path: "/product/:id",
    element: <Product />,
  },
  {
    path: "/download",
    element: <Download />,
  },
  // {
  //   path: "/periphery",
  //   element: <Periphery />,
  // },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/common",
    element: <Common />,
  },
  // {
  //     path: "/about",
  //     element: <About />
  // },
  // {
  //     path: "*",
  //     element: <NotFound />
  // },
];

export default routes;