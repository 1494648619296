import request from '../utils/request'

// 获取下载列表
export function getDownloadList(query) {
  return request({
    url: '/front/download/list',
    method: 'get',
    params: query
  })
}
