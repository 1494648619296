import { useContext, useEffect, useState } from "react";
import { LanContext } from "../../utils/lanContext";
import "./index.scss";
import { Select, Table } from "antd";
import { getProdList } from "../../apis/prod";
import { getDownloadList } from "../../apis/download";

export default function Index() {
  const [prodList, setProdList] = useState([]);
  const [downloadList, setDownloadList] = useState([]);
  // table数据没有cn/en
  const [tableData, setTableData] = useState([]);
  const { pageLan, curPageLan } = useContext(LanContext);

  const handleChange = (value) => {
    getProdList({
      pageNum: 1,
      pageSize: 999,
      prodType: value,
    }).then((res) => {
      setProdList(res.rows);
    });
  };

  const handleProdChange = (value) => {
    getDownloadList({ pageNum: 1, pageSize: 999, prodId: value }).then(
      (res) => {
        setDownloadList(res.rows);
      }
    );
  };

  // 构造tabledata
  useEffect(() => {
    if (curPageLan) {
      const tempData = downloadList.map((item) => {
        return item["download" + curPageLan];
      });
      setTableData(tempData);
    }
  }, [curPageLan, downloadList]);
  return (
    <div className="page-download">
      <div className="title-wrap flex center flex-direction" style={{background: `url("${process.env.REACT_APP_BASE_API + pageLan.pageConf?.downloadBgImg}") no-repeat`}}>
        <div className="title download-title-text">{pageLan.pageConf?.downloadTitle}</div>
        <div className="des download-des-text ">{pageLan.pageConf?.downloadDes}</div>
      </div>
      <div className="pro-select flex">
        <Select
          className="select-item"
          popupClassName="download-search-text"
          placeholder={pageLan.download?.product}
          onChange={handleChange}
          options={pageLan.download?.prodTypeOptions}
        />
        <Select
          className="select-item"
          placeholder={pageLan.download?.model}
          onChange={handleProdChange}
          options={prodList?.map((item) => {
            const info = item["prod" + curPageLan];
            return { value: info.prodId, label: info.prodName };
          })}
        />
      </div>
      <div className="table-wrap">
        <Table
          rowKey="id"
          dataSource={tableData}
          columns={pageLan.download?.columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
