import React, { useContext, useEffect, useState } from "react";
import { getNoticeList } from "../../apis/common";
import { LanContext } from "../../utils/lanContext";
import "./index.scss";

let timer = null;
let timeouter = null;
let canBegin = false;
const ScrollingText = () => {
  const [data, setData] = useState();
  const [showText, setShowText] = useState("");
  const [text, setText] = useState("");
  const { curPageLan } = useContext(LanContext);

  useEffect(() => {
    if (!data) {
      getNoticeList({ pageNum: 1, pageSize: 1, noticeType: "GREETINGS" }).then(
        (res) => {
          setData(res.rows?.[0]);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!text) {
      return;
    }
    let wordArr = text.split("。").map((item) => {
      return item.split("");
    });
    let showArrIndex = 0; // 第一层index
    let showWordIndex = 0; // 第二层index
    let showLength = wordArr[0].length;
    let showTextSync = ""; // 当前显示内容
    timer = setInterval(interFun, 100);
    timeouter = null;
    canBegin = false;
    function interFun() {
      // 一句话显示完成，逐个递减
      // console.log(showArrIndex, wordArr.length, showWordIndex, showLength);
      // 每次执行完后停顿一下
      if (showWordIndex === showLength && !canBegin) {
        clearInterval(timer);
        timeouter = setTimeout(() => {
          timer = setInterval(interFun, 100);
          clearTimeout(timeouter);
        }, 2000);
        canBegin = true;
        // setCursorStyle({ animationDuration: "800ms" });
        return;
      }
      if (showWordIndex === showLength && canBegin) {
        let len = showTextSync.length;
        // 递减完成，显示下一句话
        if (len === 0) {
          // 没有下一句话，重新显示
          showArrIndex += 1;
          showWordIndex = 0;
          if (showArrIndex === wordArr.length) {
            showArrIndex = 0;
          }
          showLength = wordArr[showArrIndex].length;
          canBegin = false;
          return;
        }
        showTextSync = showTextSync.slice(0, len - 1);
        setShowText(showTextSync);
        // setCursorStyle({ animationDuration: "400ms" });
        return;
      }
      // 一个字一个字显示
      const curWord = wordArr[showArrIndex][showWordIndex];
      showTextSync += curWord;
      showWordIndex++;
      setShowText(showTextSync);
    }
    return () => {
      clearInterval(timer);
      clearTimeout(timeouter);
    };
  }, [text]);

  useEffect(() => {
    if (data) {
      const key =
        curPageLan === "En" ? "noticeContent" + curPageLan : "noticeContent";
      clearInterval(timer);
      clearTimeout(timeouter);
      canBegin = false;
      setText(data[key]);
    }
  }, [data, curPageLan]);

  return (
    <div className="com-text-show-by-single show-by-single-text">
      {showText}
      <span className="text-cursor show-by-single-cursor"></span>
    </div>
  );
};

export default ScrollingText;
