import request from '../utils/request'

// 获取产品列表
export function getProdList(query) {
  return request({
    url: '/front/prod/list',
    method: 'get',
    params: query
  })
}
// 获取产品详情
export function getProdDetail(query) {
  return request({
    url: '/front/prod/'+query,
    method: 'get'
  })
}
