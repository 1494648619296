import {useState, useEffect} from "react";
import "./index.scss";
import { getNoticeList } from "../../apis/common";
import { useSearchParams } from "react-router-dom";

export default function Index() {
  const [text, setText] = useState("");
  const [searVal, setSearVal] = useState("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const key = searchParams.get("key");
    setSearVal(key);
  }, [searchParams]);
  useEffect(() => {
    if (searVal) {
      getNoticeList({ pageNum: 1, pageSize: 1, noticeType: searVal }).then(
        (res) => {
          setText(res.rows?.[0]?.noticeContent);
        }
      );
    }
  }, [searVal]);
  return (
    <div className="page-common">
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}
