import { useEffect } from "react";
import { getPageList } from "../apis/common";

const defaultStyle = {
  /**
   * pc 配置
   */
  // 菜单-标题
  menuItemTextSize: "14px",
  menuItemTextWeight: 500,
  menuItemTextColor: "#a6adb4",
  // 菜单-标题-选中
  menuItemTextActiveSize: "14px",
  menuItemTextActiveWeight: 500,
  menuItemTextActiveColor: "#fff",
  // 菜单-标题-鼠标悬浮
  menuItemTextHoverSize: "14px",
  menuItemTextHoverWeight: 500,
  menuItemTextHoverColor: "#fff",
  // 问候语
  showBySingleTextSize: "20px",
  showBySingleTextWeight: 500,
  showBySingleTextColor: "#fff",
  // 问候语-光标
  showBySingleCursorColor: "#aaa",
  // 底部链接-标题
  bottomLinkTitleTextSize: "14px",
  bottomLinkTitleTextWeight: 500,
  bottomLinkTitleTextColor: "#fff",
  // 底部链接-内容
  bottomLinkItemTextSize: "14px",
  bottomLinkItemTextWeight: 500,
  bottomLinkItemTextColor: "#a6adb4",
  // 底部链接-内容-鼠标悬浮
  bottomLinkItemTextHoverSize: "14px",
  bottomLinkItemTextHoverWeight: 500,
  bottomLinkItemTextHoverColor: "#fff",
  // 产品弹框-标题
  proModalTitleTextSize: "30px",
  proModalTitleTextWeight: 500,
  proModalTitleTextColor: "#fff",
  // 产品弹框-产品名称
  proModalItemTextSize: "18px",
  proModalItemTextWeight: 500,
  proModalItemTextColor: "#fff",
  // 下载页-标题
  downloadTitleTextSize: "30px",
  downloadTitleTextWeight: 500,
  downloadTitleTextColor: "#fff",
  // 下载页-描述
  downloadDesTextSize: "16px",
  downloadDesTextWeight: 500,
  downloadDesTextColor: "#a6adb4",
  // 下载页-表格-标题
  downloadTableTitleTextSize: "14px",
  downloadTableTitleTextWeight: 500,
  downloadTableTitleTextColor: "#a6adb4",
  // 下载页-表格-内容
  downloadTableContentTextSize: "14px",
  downloadTableContentTextWeight: 500,
  downloadTableContentTextColor: "#ccc",
  // 下载页-表格-内容-下载按钮
  downloadTableBtnTextSize: "14px",
  downloadTableBtnTextWeight: 500,
  downloadTableBtnTextColor: "#1668dc",
  // 下载页-表格-内容-下载按钮-鼠标悬浮
  downloadTableBtnTextHoverSize: "14px",
  downloadTableBtnTextHoverWeight: 500,
  downloadTableBtnTextHoverColor: "#1b327e",
  // 搜索页-产品名称
  searchProductNameTextSize: "20px",
  searchProductNameTextWeight: 500,
  searchProductNameTextColor: "#fff",
  // 搜索页-产品描述
  searchProductDesTextSize: "14px",
  searchProductDesTextWeight: 500,
  searchProductDesTextColor: "#a6adb4",
  // 产品详情-购买栏-产品名称
  productBuyProdNameTextSize: "18px",
  productBuyProdNameTextWeight: 1000,
  productBuyProdNameTextColor: "#fff",
  // 产品详情-购买栏-购买
  productBuyTextSize: "14px",
  productBuyTextWeight: 500,
  productBuyTextColor: "#fff",
  // 产品详情-图文-产品名称
  productProdNameTextSize: "20px",
  productProdNameTextWeight: 500,
  productProdNameTextColor: "#fff",
  // 产品详情-图文-标题
  productFeatureTitleTextSize: "36px",
  productFeatureTitleTextWeight: 500,
  productFeatureTitleTextColor: "#44d62c",
  // 产品详情-图文-描述
  productFeatureDesTextSize: "16px",
  productFeatureDesTextWeight: 500,
  productFeatureDesTextColor: "#a6adb4",
  // 产品详情-技术规格-分隔标题
  productSpecTitleTextSize: "20px",
  productSpecTitleTextWeight: 1000,
  productSpecTitleTextColor: "#44d62c",
  // 产品详情-技术规格-标题
  productSpecItemTitleTextSize: "18px",
  productSpecItemTitleTextWeight: 500,
  productSpecItemTitleTextColor: "#fff",
  // 产品详情-技术规格-描述
  productSpecItemDesTextSize: "14px",
  productSpecItemDesTextWeight: 500,
  productSpecItemDesTextColor: "#a6adb4",

  /**
   * h5 配置
   */
  // 菜单-标题
  menuItemTextH5Size: "14px",
  menuItemTextH5Weight: 500,
  menuItemTextH5Color: "#a6adb4",
  // 菜单-标题-选中
  menuItemTextActiveH5Size: "14px",
  menuItemTextActiveH5Weight: 500,
  menuItemTextActiveH5Color: "#fff",
  // 问候语
  showBySingleTextH5Size: "16px",
  showBySingleTextH5Weight: 500,
  showBySingleTextH5Color: "#fff",
  // 问候语-光标
  showBySingleCursorH5Color: "#aaa",
  // 底部链接-标题
  bottomLinkTitleTextH5Size: "12px",
  bottomLinkTitleTextH5Weight: 500,
  bottomLinkTitleTextH5Color: "#fff",
  // 底部链接-内容
  bottomLinkItemTextH5Size: "12px",
  bottomLinkItemTextH5Weight: 500,
  bottomLinkItemTextH5Color: "#a6adb4",
  // 产品弹框-标题
  proModalTitleTextH5Size: "18px",
  proModalTitleTextH5Weight: 500,
  proModalTitleTextH5Color: "#fff",
  // 产品弹框-产品名称
  proModalItemTextH5Size: "14px",
  proModalItemTextH5Weight: 500,
  proModalItemTextH5Color: "#fff",
  // 下载页-标题
  downloadTitleTextH5Size: "30px",
  downloadTitleTextH5Weight: 500,
  downloadTitleTextH5Color: "#fff",
  // 下载页-描述
  downloadDesTextH5Size: "16px",
  downloadDesTextH5Weight: 500,
  downloadDesTextH5Color: "#a6adb4",
  // 下载页-表格-标题
  downloadTableTitleTextH5Size: "14px",
  downloadTableTitleTextH5Weight: 500,
  downloadTableTitleTextH5Color: "#a6adb4",
  // 下载页-表格-内容
  downloadTableContentTextH5Size: "14px",
  downloadTableContentTextH5Weight: 500,
  downloadTableContentTextH5Color: "#ccc",
  // 下载页-表格-内容-下载按钮
  downloadTableBtnTextH5Size: "14px",
  downloadTableBtnTextH5Weight: 500,
  downloadTableBtnTextH5Color: "#1668dc",
  // 下载页-表格-内容-下载按钮-鼠标悬浮
  downloadTableBtnTextHoverH5Size: "14px",
  downloadTableBtnTextHoverH5Weight: 500,
  downloadTableBtnTextHoverH5Color: "#1b327e",
  // 搜索页-产品名称
  searchProductNameTextH5Size: "14px",
  searchProductNameTextH5Weight: 500,
  searchProductNameTextH5Color: "#fff",
  // 搜索页-产品描述
  searchProductDesTextH5Size: "12px",
  searchProductDesTextH5Weight: 500,
  searchProductDesTextH5Color: "#a6adb4",
  // 产品详情-购买栏-产品名称
  productBuyProdNameTextH5Size: "16px",
  productBuyProdNameTextH5Weight: 1000,
  productBuyProdNameTextH5Color: "#fff",
  // 产品详情-购买栏-购买
  productBuyTextH5Size: "14px",
  productBuyTextH5Weight: 500,
  productBuyTextH5Color: "#fff",
  // 产品详情-图文-产品名称
  productProdNameTextH5Size: "14px",
  productProdNameTextH5Weight: 500,
  productProdNameTextH5Color: "#fff",
  // 产品详情-图文-标题
  productFeatureTitleTextH5Size: "26px",
  productFeatureTitleTextH5Weight: 500,
  productFeatureTitleTextH5Color: "#44d62c",
  // 产品详情-图文-描述
  productFeatureDesTextH5Size: "14px",
  productFeatureDesTextH5Weight: 500,
  productFeatureDesTextH5Color: "#a6adb4",
  // 产品详情-技术规格-分隔标题
  productSpecTitleTextH5Size: "16px",
  productSpecTitleTextH5Weight: 1000,
  productSpecTitleTextH5Color: "#44d62c",
  // 产品详情-技术规格-标题
  productSpecItemTitleTextH5Size: "14px",
  productSpecItemTitleTextH5Weight: 500,
  productSpecItemTitleTextH5Color: "#fff",
  // 产品详情-技术规格-描述
  productSpecItemDesTextH5Size: "12px",
  productSpecItemDesTextH5Weight: 500,
  productSpecItemDesTextH5Color: "#a6adb4",
};

export default function useStyle() {
  useEffect(() => {
    const bodyStyle = document.getElementsByTagName("body")[0].style;
    Object.keys(defaultStyle).forEach((key) => {
      bodyStyle.setProperty("--" + key, defaultStyle[key]);
    });
    getPageList({type: 'font'}).then((res) => {
      res.data.forEach((item) => {
        if (item.fieldValue) {
          bodyStyle.setProperty("--" + item.fieldKey, item.fieldValue);
        }
      })
    })
  }, []);
}
