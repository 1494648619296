import React from "react";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import "./index.scss";

export default function index({ text, buyUrl }) {
  const items = JSON.parse(buyUrl || "[]").map((item) => {
    return {
      key: item.key,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={item.value}>
          {item.label}
        </a>
      ),
    };
  });
  return (
    <div className="page-buy-btn pointer text-white">
      {/* <div onClick={() => window.open(buyUrl)}>{text || "购买"}</div> */}
      <Dropdown menu={{ items }}>
        <div onClick={(e) => e.preventDefault()}>
          <Space>
            <span className="product-buy-text">{text || "购买"}</span>
            <DownOutlined />
          </Space>
        </div>
      </Dropdown>
    </div>
  );
}
