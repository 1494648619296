import React, { useEffect, useState, useContext } from "react";
import { Input } from "antd";
import { useSearchParams } from "react-router-dom";
import { LanContext } from "../../utils/lanContext";
import "./index.scss";
import { getProdList } from "../../apis/prod";
import { useNavigate } from "react-router-dom";

const { Search } = Input;
export default function Index() {
  const [prodRes, setProdRes] = useState({});
  const [searVal, setSearVal] = useState("");
  const [searchParams] = useSearchParams();
  const { pageLan, curPageLan } = useContext(LanContext);
  // 路由
  const navigate = useNavigate();

  useEffect(() => {
    const key = searchParams.get("key");
    setSearVal(key);
  }, [searchParams]);

  const onSearch = (val) => {
    setSearVal(val);
  };
  // 请求数据
  useEffect(() => {
    if (searVal) {
      getProdList({
        pageNum: 1,
        pageSize: 999,
        keywords: searVal,
      }).then((res) => {
        setProdRes(res);
      });
    }
  }, [searVal]);
  // 跳转详情
  const handleClickItem = (prodId) => {
    navigate("/product/" + prodId);
  };
  return (
    <div className="page-search">
      <div className="sear-wrap text-left">
        <Search
          placeholder="Search Goubang.com"
          onSearch={onSearch}
          size="large"
          className="sear-input"
        />
        <div className="sear-tip">
          {curPageLan === "Cn"
            ? pageLan.search?.item1 +
              searVal +
              pageLan.search?.item2 +
              prodRes?.total +
              pageLan.search?.item3
            : pageLan.search?.item1 +
              prodRes?.total +
              pageLan.search?.item2 +
              searVal +
              pageLan.search?.item3}
        </div>
        <div className="flex flex-wrap">
          {prodRes.rows?.map((item) => {
            const info = item["prod" + curPageLan];
            return (
              <div
                key={info.prodId}
                className="sear-item pointer"
                onClick={() => handleClickItem(info.prodId)}
              >
                <div className="img-wrap">
                  <img
                    className="img"
                    src={process.env.REACT_APP_BASE_API + info.prodImgThumbnail}
                  />
                </div>
                <div className="bt-wrap text-left">
                  <div className="title search-product-name-text">{info.prodName}</div>
                  <div className="flex justify-between align-center">
                    <div className="des search-product-des-text">{info.des}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
