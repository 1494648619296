import request from "../utils/request";

// 获取通知列表
export function getNoticeList(query) {
  return request({
    url: "/front/notice/list",
    method: "get",
    params: query,
  });
}
// 获取页面配置
export function getPageList(query) {
  return request({
    url: "/front/page/list",
    method: "get",
    params: query
  });
}
