import { Modal } from "antd";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { getProdList } from "../../apis/prod";
import { LanContext } from "../../utils/lanContext";

// import computer from "../../static/imgs/temp/computer.jpg";

const ProModal = ({ pos, modalWidth, curItem }) => {
  const [prodList, setProdList] = useState([]);
  const [open, setOpen] = useState(false);
  const { curPageLan } = useContext(LanContext);
  // 路由
  const navigate = useNavigate();
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setProdList([]);
    setOpen(false);
  };
  useEffect(() => {
    if (pos && pos.length) {
      showModal();
    }
  }, [pos]);
  const handleClickItem = (prodId) => {
    navigate("/product/" + prodId);
    handleCancel();
  };
  // 请求数据
  useEffect(() => {
    if (pos && pos.length) {
      getProdList({
        pageNum: 1,
        pageSize: 999,
        prodType: curItem.searchKey,
      }).then((res) => {
        setProdList(res.rows);
      });
    }
  }, [curItem, pos]);
  return (
    <div className="com-pro-modal">
      {/* <Button type="primary" onClick={showModal}>
        Open Modal with customized footer
      </Button> */}
      <Modal
        open={open}
        title=""
        destroyOnClose
        width={modalWidth}
        className="com-pro-modal-main"
        closeIcon={<></>}
        style={
          pos[0] ? { left: pos[0] || 0, top: pos[1] || 0, margin: 0 } : null
        }
        onCancel={handleCancel}
        footer={[]}
      >
        <div>
          <div onClick={handleCancel} className="modal-title text-center pro-modal-title-text">
            {curItem?.label}
          </div>
          <div className="content-main flex flex-wrap">
            {prodList.map((item) => {
              const info = item["prod" + curPageLan];
              return (
                <div
                  key={info.prodId}
                  className="pro-item pointer flex align-center flex-direction"
                  onClick={() => handleClickItem(info.prodId)}
                >
                  <img className="img" src={process.env.REACT_APP_BASE_API + info.prodImgThumbnail} />
                  <div className="title pro-modal-item-text">{info.prodName}</div>
                  {/* <div className="des">{info.des}</div>
                  <div className="more">Learn More &gt;</div> */}
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ProModal;
